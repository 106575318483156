* {
  word-break: keep-all;
}
html {
  font-size: 16px;
}

html,
body {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-weight: 300;
  font-family: "Noto Sans KR";
  color: #000;
  letter-spacing: -0.025em;
  line-height: normal;
  word-wrap: break-word;
  background-color: #fff;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

header,
footer,
nav,
article,
aside,
hgroup,
section,
figure,
div,
p,
ul,
li,
ol {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  white-space: pre-wrap;
}

h1,
h2,
h3,
h4,
h5,
h6,
dl,
th,
td,
dt,
dd,
em,
span,
strong,
fieldset,
label,
a {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
}

a {
  font-weight: 300;
  color: #000;
  text-indent: 0;
  text-decoration: none;
}

ul,
ol,
li {
  list-style: none;
}

fieldset {
  border: none;
}

legend,
caption {
  overflow: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  color: transparent;
  border: 0;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1rem;
  table-layout: fixed;
}

table td,
table th {
  padding: 0;
}

button {
  overflow: visible;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-family: "Noto Sans KR";
  text-align: center;
  vertical-align: middle;
  background: none;
  border: none;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

button span {
  position: relative;
  display: inline-block;
}

/* ir */
.ir {
  overflow: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  color: transparent;
  border: 0;
  clip: rect(0 0 0 0);
}

@media (max-width: 576px) {
  html {
    font-size: 10px;
  }
}
