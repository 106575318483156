@font-face {
  /* 사용할 폰트의 이름 */
  font-family: "Noto Sans KR";
  font-display: fallback;

  /* 
  src: 사용할 폰트의 위치 및 형식 
    Safari, Android, iOS => ttf
    Modern Browsers => woff
    IE6-IE8 => eot
  */
  src: url("./noto-sans-kr-v21-latin_korean-300.eot?iefix")
      format("embedded-opentype"),
    url("./noto-sans-kr-v21-latin_korean-300.woff") format("woff"),
    url("./noto-sans-kr-v21-latin_korean-300.woff2") format("woff2");

  /* 사용할 폰트의 기본 스타일 */
  font-weight: 300;
  font-style: normal;
}

@font-face {
  /* 사용할 폰트의 이름 */
  font-family: "Noto Sans KR";
  font-display: fallback;

  /* 
  src: 사용할 폰트의 위치 및 형식 
    Safari, Android, iOS => ttf
    Modern Browsers => woff
    IE6-IE8 => eot
  */
  src: url("./noto-sans-kr-v21-latin_korean-500.eot?iefix")
      format("embedded-opentype"),
    url("./noto-sans-kr-v21-latin_korean-500.woff") format("woff"),
    url("./noto-sans-kr-v21-latin_korean-500.woff2") format("woff2");

  /* 사용할 폰트의 기본 스타일 */
  font-weight: 500;
  font-style: normal;
}

@font-face {
  /* 사용할 폰트의 이름 */
  font-family: "Noto Sans KR";
  font-display: fallback;

  /* 
  src: 사용할 폰트의 위치 및 형식 
    Safari, Android, iOS => ttf
    Modern Browsers => woff
    IE6-IE8 => eot
  */
  src: url("./noto-sans-kr-v21-latin_korean-700.eot?iefix")
      format("embedded-opentype"),
    url("./noto-sans-kr-v21-latin_korean-700.woff") format("woff"),
    url("./noto-sans-kr-v21-latin_korean-700.woff2") format("woff2");

  /* 사용할 폰트의 기본 스타일 */
  font-weight: 700;
  font-style: normal;
}
