/* html,
body {
  height: 100%;
  letter-spacing: 0;
} */

/* pc common */

.inner_header {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 30px 33px;
}

#iSci_header .logo_isci {
  height: 39px;
  background: url("../lib/images/logo_iscilab.png") no-repeat 0 0;
  text-align: center;
}

#iSci_header .logo_isci a {
  display: block;
  width: 148px !important;
}

#iSci_header .logo_isci p {
  margin-top: min(1.2rem, 15px);
  font-size: 0.9rem;
}

.iSci_nav {
  justify-items: end;
}

.iSci_nav ul > li {
  display: inline-block;
  width: 160px;
  text-align: center;
  vertical-align: middle;
}

.iSci_nav ul > li:first-child {
  padding-left: 0;
}

.iSci_nav ul > li:last-child {
  padding-right: 0;
}

.iSci_nav ul > li {
  position: relative;
  box-sizing: border-box;
}

.nav_1depth {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.iSci_nav .nav_1depth > li > button {
  font-size: 18px;
  color: #2d2d2d;
}

.iSci_nav .nav_1depth > li > button span {
  display: block;
  position: relative;
  font-weight: 700;
  text-transform: uppercase;
}

.iSci_nav .nav_1depth > li > button .on {
  color: #277ec9;
}

.iSci_nav .nav_1depth > li .nav_sub {
  position: absolute;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  z-index: 1;
}

.iSci_nav .nav_1depth > li .nav_sub li {
  display: block;
  font-size: 15px;
  margin: 0;
  padding: 10px 0;
  white-space: nowrap;
}

.iSci_nav .nav_1depth > li .nav_sub li > button {
  color: #2d2d2d;
}

.iSci_nav .nav_1depth > li .nav_sub li .on {
  color: #00b580;
}

.nav_lang {
  float: right;
  padding-top: 9px;
}

#iSci_header .lang_kr {
  width: 28px;
  height: 28px;
  background: url("../lib/images/lang_kr.png") no-repeat 0 0;
}

#iSci_header .lang_kr a {
  display: block;
  width: 28px;
  height: 28px;
}

#iSci_header .lang_en {
  width: 28px;
  height: 28px;
  background: url("../lib/images/lang_en.png") no-repeat 0 0;
}

#iSci_header .lang_en a {
  display: block;
  width: 28px;
  height: 28px;
}

#iSci_header .lang_vn {
  width: 28px;
  height: 28px;
  background: url("../lib/images/lang_vn.png") no-repeat 0 0;
}

#iSci_header .lang_vn a {
  display: block;
  width: 28px;
  height: 28px;
}

#iSci_contents {
  position: relative;
}

#iSci_contents .intro {
  width: 90vw;
  min-width: 1000px;
  margin: 0 auto;
}

.main_visual {
  width: 90vw;
  min-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: max(80vh, 600px);
  background: url("../lib/images/bg_main visual.png") no-repeat 0 0;
  background-size: contain;
}

.visual_copy {
  height: 563px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.visual_copy .copy_txt01 {
  width: fit-content;
  position: absolute;
  font-size: 5rem;
  font-weight: 500;
  color: #277ec9;
  line-height: 6rem;
  padding-left: 10vw;
  z-index: 1;
}

.visual_copy .copy_txt02 {
  width: fit-content;
  position: absolute;
  font-size: 2rem;
  font-weight: 300;
  color: #277ec9;
  bottom: 50%;
  padding-left: 10vw;
  line-height: 40px;
  z-index: 1;
}

.visual_copy .visual_img {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0%;
  padding-right: min(50%, 50px);
}
.visual_copy .visual_img img {
  /* width: 50vw; */
  min-width: 300px;
  max-width: 400px;
}

.visual_copy .m_visual_img {
  display: none;
}

.visual_page {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 20px 0;
}

.visual_page a {
  padding: 0 10px;
}

.btn_visualpage_pre {
  width: 20px;
  height: 20px;
  background: url("../lib/images/arrow_left.png") no-repeat 0 0;
  background-size: 100% 100% !important;
  margin-right: 50px;
}

.btn_visualpage_next {
  width: 20px;
  height: 20px;
  background: url("../lib/images/arrow_right.png") no-repeat 0 0;
  background-size: 100% 100% !important;
  margin-left: 50px;
}

.btn_scroll {
  width: 63px;
  height: 46px;
  background: url("../lib/images/scroll.png") no-repeat 0 0;
  background-size: 100% 100% !important;
  margin-bottom: 10px;
}

.landing_scroll {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  /* padding: 50px 0 0 0; */
}

.main_about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 60vh;
  background-color: #277ec9;
  background: url("../lib/images/bg_about.png") no-repeat 50% 50%;
  width: 80vw;
  margin: 0 auto;
  padding: 4rem 0;
}

.main_about .about_iSci {
  font-size: 4rem;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: -3px;
}

.main_about h2 .line {
  display: inline-block;
  position: relative;
}

.main_about h2 .line:after {
  content: "";
  display: block;
  height: 11px;
  margin-top: -22px;
  background-color: #0be0ef;
}

.main_about p {
  font-size: 2rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.5;
  margin-top: 59px;
}

.main_about .gray {
  color: #cccccc;
}

.main_ourwork {
  display: flex;
  flex-direction: column;
  justify-content: center;

  min-height: 60vh;
  padding: 4rem;
  background-color: #f1f8ff;

  width: 80vw;
  margin: 0 auto;
  padding: 4rem 0;
}

.main_ourwork .ourwork_iSci {
  font-size: 4rem;
  font-weight: 700;
  color: #2d2d2d;
  letter-spacing: -3px;
}

.main_ourwork h2 .line {
  display: inline-block;
  position: relative;
}

.main_ourwork h2 .line:after {
  content: "";
  display: block;
  height: 11px;
  margin-top: -22px;
  background-color: #0be0ef;
}

.main_ourwork p {
  font-size: 1.5rem;
  font-weight: 300;
  color: #2d2d2d;
  line-height: 1.5;
  letter-spacing: 0;
  margin-top: 20px;
}

.ourwork_contents .work {
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 30px;
  padding: 2rem 2rem;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ourwork_contents .work img {
  width: 30%;
  object-fit: contain;
}
.ourwork_contents .work_text {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.ourwork_contents .work .tit_bold {
  font-size: 1.5rem;
  font-weight: 500;
  color: #2d2d2d;
  line-height: 1.5;
  letter-spacing: -1px;
}

.ourwork_contents .work .text {
  font-size: 1.5rem;
  font-weight: 300;
  color: #2d2d2d;
  line-height: 1.5;
  letter-spacing: -1px;
}

.ourwork_contents .over {
  background: #ffffff;
  border: 2px solid #277ec9;
  border-radius: 30px;
  padding: 30px 45px;
  margin-top: 50px;
  box-shadow: 3px 3px 10px grey;
}

.ourwork_contents .btn_pastwork {
  background-color: #277ec9;
  padding: 20px 45px;
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  margin: 50px auto 0;
}

.ourwork_contents .btn_pastwork span {
  color: #ffffff;
  margin-left: 20px;
}

.main_news {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 60vh;
  background-color: #ffffff;

  width: 80vw;
  margin: 0 auto;
  padding: 4rem 0;
}

.main_news:after {
  content: "";
  display: block;
  clear: both;
}

.main_news .news_iSci {
  font-size: 4rem;
  font-weight: 700;
  color: #2d2d2d;
  letter-spacing: -3px;
  float: left;
}

.main_news h2 .line {
  display: inline-block;
  position: relative;
}

.main_news h2 .line:after {
  content: "";
  display: block;
  height: 11px;
  margin-top: -22px;
  background-color: #0be0ef;
}

.main_news p {
  float: left;
  font-size: 20px;
  font-weight: 300;
  color: #2d2d2d;
  line-height: 1.5;
  letter-spacing: 0;
  /* margin: 34px 0 0 30px; */
}

.tit_news_area {
  position: relative;
  display: block;
  margin-bottom: 1rem;
}

.tit_news_area:after {
  content: "";
  display: block;
  clear: both;
}

.news_contents {
  display: block;
  float: left;
  width: 100%;
}

.news_contents .news_item {
  position: relative;
  display: block;
  border-bottom: 1px solid #cccccc;
  padding: 10px 0;
  padding-bottom: 30px;
}

.news_contents .news_item .tit_bold {
  display: block;
  font-size: 30px;
  font-weight: 500;
  color: #2d2d2d;
  line-height: 1.5;
  letter-spacing: -2px;
  margin: 20px 0;
  width: 90%;
}

.news_contents .news_item .text {
  font-size: 20px;
  font-weight: 300;
  color: #2d2d2d;
  line-height: 1.5;
  letter-spacing: -1px;
  width: 90%;
  display: block;
}

.news_contents .news_item img {
  width: 3rem;
  position: absolute;
  top: 69px;
  right: 10px;
}

.news_contents .over {
  color: #277ec9 !important;
}

.news_contents .btn_news_more {
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  margin: 50px auto 0;
}

.news_contents .btn_news_more span {
  font-size: 30px;
  font-weight: 500;
  letter-spacing: -3px;
  text-decoration: underline;
}

.main_contactus {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* min-height: 60vh; */
  background-color: #000000;
  text-align: center;

  width: 80vw;
  margin: 0 auto;
  padding: 4rem 0;
}

.main_contactus .contactus_iSci {
  font-size: 4rem;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: -3px;
}

.main_contactus p {
  font-size: 1.3rem;
  font-weight: 300;
  color: #ffffff;
  line-height: 1.5;
  letter-spacing: 0;
  margin-top: 1rem;
}

.main_contactus .btn_contactus {
  background-color: #ffffff;
  border-radius: 50px;
  position: relative;
  margin: 0 auto;
  margin-top: 1rem;
  padding: 0.8rem 2rem;
  /* height: 3rem; */
  font-size: 1.4rem;
}
.main_contactus .btn_contactus a {
  font-weight: 600;
  color: #2d2d2d;
}

#iSci_footer .inner_footer {
  background-color: #277ec9;

  margin: 0 auto;
  padding: 30px 0;
}

#iSci_footer .inner_footer {
  display: flex;
  justify-content: center;
  align-items: stretch;
}

#iSci_footer .inner_footer .menu {
  width: 220px;
  padding: 30px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  text-align: center;
}

#iSci_footer .inner_footer .first {
  margin-left: 70px;
}

#iSci_footer .inner_footer h4 {
  padding-bottom: 13px;
}

#iSci_footer .inner_footer h4 a {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 13px;
}

#iSci_footer .inner_footer .menu ul li {
  padding: 5px 0;
}

#iSci_footer .inner_footer .menu ul li a {
  color: #ffffff;
  font-size: 18px;
  font-weight: 300;
}

#iSci_footer .inner_footer .menu:last-child {
  border-right: none;
}

#iSci_footer {
  background-color: #277ec9;
  width: 1366px;
  margin: 0 auto;
}

#iSci_footer .logo_footer {
  background-color: #277ec9;
  width: 1306px;
  margin: 0 auto;
  padding: 30px 45px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

#iSci_footer .logo_footer_isci {
  background: url("../lib/images/footer_logo_iscilab.png") no-repeat 0 0;
}

#iSci_footer .logo_footer_isci a {
  display: block;
  width: 98px;
  height: 27px;
}

#iSci_footer .footer_copy {
  color: #ffffff;
  font-size: 15px;
}

#m_iSci_footer {
  display: none;
}

.sub_visual {
  background-color: #02092e;
  height: 130px;
}

.sub_visual .inner_visual {
  position: relative;
  /* width: 1366px; */
  margin: 0 auto;
}

.sub_visual .inner_visual h2 {
  position: absolute;
  color: #ffffff;
  font-size: 25px;
  font-weight: 400;
  top: 45px;
  left: 80px;
}

/* .sub_location {
  background-color: #f8f8f8;
  height: 50px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.3);
} */

.sub_location .inner_location {
  width: auto;
  background-color: #f8f8f8;
  height: 40px;
  border-bottom: 1px solid rgba(204, 204, 204, 0.3);
  position: relative;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 1rem;
}

.sub_location .inner_location .location img {
  vertical-align: text-top;
}

.sub_location .inner_location .location span {
  color: #2d2d2d;
  font-size: 15px;
  font-weight: 400;
}

.sub_location .inner_location .location .on {
  color: #32b9dc;
  font-size: 15px;
  font-weight: 400;
}

#iSci_sub_contents {
  background: #ffffff;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

#iSci_sub_contents:after {
  content: "";
  display: block;
  clear: both;
}

.intro_company_nav {
  position: relative;
  padding: 2rem 1rem;
  display: flex;
  justify-content: center;
  width: auto;
  min-width: max-content;
}

.intro_company_nav ul li {
  display: inline-block;
  padding: 0 30px;
  border-right: 1px solid #d2d2d2;
}

.intro_company_nav ul li:last-child {
  border-right: none;
}

.intro_company_nav ul li a {
  color: #2d2d2d;
  font-size: 20px;
  font-weight: 400;
}

.intro_company_nav ul li .over {
  color: #00b580;
  font-size: 20px;
  font-weight: 400;
}

.intro_company_about {
  width: 100%;
  height: 500px;
  margin: 0 auto;
  background-color: #277ec9;
  text-align: center;
}
.company_box {
  height: 500px;
  background: url("../lib/images/bg_sub_about.png") no-repeat 50% 50%;
}

.intro_company_about .sub_about {
  font-size: 44px;
  font-weight: 700;
  color: #ffffff;
  letter-spacing: -3px;
  padding-top: 35px;
}

.intro_company_about h2 .line {
  display: inline-block;
  position: relative;
}

.intro_company_about h2 .line:after {
  content: "";
  display: block;
  height: 11px;
  margin-top: -18px;
  background-color: #0be0ef;
}

.intro_company_about p {
  font-size: 25px;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.5;

  margin-top: 50px;
}

.intro_ceo {
  padding-bottom: 100px;
  display: flex;
  justify-content: center;
}

.div_ceo {
  width: 1388px;
}

.intro_ceo .tit {
  text-align: center;
}

.intro_ceo .sub_ceo {
  font-size: 44px;
  font-weight: 700;
  color: #2d2d2d;
  letter-spacing: -3px;
  padding-top: 100px;
}

.intro_ceo h2 .line {
  display: inline-block;
  position: relative;
}

.intro_ceo h2 .line:after {
  content: "";
  display: block;
  height: 11px;
  margin-top: -15px;
  background-color: #0be0ef;
}

.intro_ceo img {
  float: left;
  display: inline-block;
  margin: 80px 40px;
  width: 20%;
}

.intro_ceo p {
  font-size: 20px;
  font-weight: 400;
  color: #2d2d2d;
  line-height: 1.8;

  margin-top: 80px;
}

.intro_org {
  text-align: center;
  background-color: #f9f9f9;
  padding: 100px 0;
}

.intro_org .sub_org {
  font-size: 44px;
  font-weight: 700;
  color: #2d2d2d;
  letter-spacing: -3px;
}

.intro_org h2 .line {
  display: inline-block;
  position: relative;
}

.intro_org h2 .line:after {
  content: "";
  display: block;
  height: 11px;
  margin-top: -15px;
  background-color: #0be0ef;
}

.intro_org p {
  font-size: 25px;
  font-weight: 400;
  color: #2d2d2d;
  line-height: 1.5;
  letter-spacing: 3px;
  margin-top: 50px;
}

.intro_org img {
  margin-top: 100px;
}

.intro_history {
  text-align: center;
  padding: 100px 0;
  background-color: #f1f8ff;
}

.intro_history .sub_history {
  font-size: 44px;
  font-weight: 700;
  color: #2d2d2d;
  letter-spacing: -3px;
}

.intro_history h2 .line {
  display: inline-block;
  position: relative;
}

.intro_history h2 .line:after {
  content: "";
  display: block;
  height: 11px;
  margin-top: -15px;
  background-color: #0be0ef;
}

.intro_history > p {
  font-size: 25px;
  font-weight: 400;
  color: #2d2d2d;
  line-height: 1.5;
  letter-spacing: 1px;
  margin-top: 50px;
}

.intro_history .infograph {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  text-align: justify;
}

.intro_history .infograph p {
  font-size: 20px;
  font-weight: 400;
  color: #2d2d2d;
  line-height: 1.5;
  letter-spacing: 3px;
}

.intro_partner {
  text-align: center;
  padding: 100px 0;
}

.intro_partner .sub_partner {
  font-size: 44px;
  font-weight: 700;
  color: #2d2d2d;
  letter-spacing: -3px;
}

.intro_partner h2 .line {
  display: inline-block;
  position: relative;
}

.intro_partner h2 .line:after {
  content: "";
  display: block;
  height: 11px;
  margin-top: -15px;
  background-color: #0be0ef;
}

.intro_partner img {
  margin-top: 100px;
}

.intro_map {
  text-align: center;
  background-color: #f9f9f9;
  padding: 5rem 0;
}

.intro_map .sub_map {
  font-size: 44px;
  font-weight: 700;
  color: #2d2d2d;
  letter-spacing: -3px;
  margin-bottom: 5rem;
}

.intro_map h2 .line {
  display: inline-block;
  position: relative;
}

.intro_map h2 .line:after {
  content: "";
  display: block;
  height: 11px;
  margin-top: -15px;
  background-color: #0be0ef;
}
.maps {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  line-height: 1.8;
}

.intro_map .map img {
  /* width: 100%; */
}
.intro_map .bold {
  font-size: 20px;
  font-weight: 500;
  color: #2d2d2d;
}

/* inquiry */

.iSci_inquiry {
  text-align: center;
  padding: 100px 0;
  margin: 0 auto;
}

.iSci_inquiry .sub_inquiry {
  font-size: 44px;
  font-weight: 700;
  color: #2d2d2d;
  letter-spacing: -3px;
  margin-bottom: 100px;
}

.iSci_inquiry h2 .line {
  display: inline-block;
  position: relative;
}

.iSci_inquiry h2 .line:after {
  content: "";
  display: block;
  height: 11px;
  margin-top: -15px;
  background-color: #0be0ef;
}

.iSci_inquiry > p {
  font-size: 25px;
  font-weight: 500;
  color: #2d2d2d;
  line-height: 1.5;
  letter-spacing: 1px;
  margin-top: 50px;
}

/* inquiry form */

.form-box {
  margin: 100px 133px 0px;
  position: relative;
  text-align: left;
}

.form-list {
  border-top: 1px solid #e1e1e1;
}

.form-list li {
  border-bottom: 1px solid #e1e1e1;
  padding: 20px 0;
}

.form-list li dl {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.form-list li dl > * {
  display: table-cell;
  vertical-align: middle;
}

.form-list li dt {
  width: 177px;
  padding-left: 30px;
  font-size: 20px;
  font-weight: 400;
  line-height: 56px;
  color: #2d2d2d;
}

.form-list li dd {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}

.form-list li dd .bar {
  display: inline-block;
  font-size: 17px;
  color: #222;
  text-align: center;
}

.form-list .input-file-box,
.form-list .input-box {
  width: 100%;
  max-width: 486px;
}

.select-box-ui {
  position: relative;
  width: 100%;
  max-width: 486px;
  border: 1px solid #e1e1e1;
  padding-left: 10px;
}

.select-box-ui button {
  display: block;
  width: 100%;
  text-align: left;
  letter-spacing: -0.01em;
  font-size: 17px;
  box-sizing: border-box;
  background: transparent;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}

.select-box-ui .selected-option {
  position: relative;
  width: 100%;
  height: 56px;
  line-height: 54px;
  color: #2d2d2d;
  padding: 40px 0 20px;
  z-index: 5;
  cursor: pointer;
}

.select-box-ui .selected-option::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  width: 21px;
  height: 21px;
  background: url(../lib/images/down-arrow.png) no-repeat 0 0;
  transform: translate(0, -50%);
}

.input-box {
  position: relative;
  box-sizing: border-box;
}

.input-box label {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translate(0, -50%);
  color: #333;
  opacity: 0.5;
  font-size: 17px;
}

.input-box input,
.input-box select {
  width: 100%;
  border: 1px solid #e1e1e1;
}

.input-box label,
.input-box input,
.input-box select {
  height: 56px;
  line-height: 54px;
  padding: 0 20px;
}

.form-list .email .input-box {
  width: calc(33.3% - 57px);
  max-width: 240px;
}

.form-list .email .bar {
  width: 57px;
  margin: 0;
}

.form-list .email .select-box-ui {
  width: calc(33.3% - 20px);
  max-width: 194px;
  margin-left: 20px;
}

.form-list .tel .input-box {
  max-width: 130px;
}

.form-list .tel .bar {
  width: 48px;
}

/* textarea-box */
.textarea-box {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  /* width: 100%; */
  box-sizing: border-box;
  font-size: 17px;
  line-height: 26px;
  text-align: left;
  border: 1px solid #e1e1e1;
}

.textarea-box label {
  position: absolute;
  top: 20px;
  left: 0;
  padding: 0 20px;
  width: 100%;
  font: inherit;
  box-sizing: border-box;
  color: #333;
  opacity: 0.5;
  letter-spacing: 0.5px;
}

.textarea-box textarea {
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  height: 182px;
  font-size: inherit;
  color: #222;
  border: 0;
  resize: none;
}

/* input-file-box */
.input-file-box {
  position: relative;
}

.input-file-box label {
  font: inherit;
}

.input-file-box input:focus {
  outline: none;
}

.input-file-box input:focus + label {
  border: 1px dotted #000;
}

.input-file-box input[type="text"] {
  width: 100%;
  height: 50px;
  border: 1px solid #ddd;
  line-height: 48px;
  padding: 0 18px;
  padding-right: 126px;
  box-sizing: border-box;
}

.input-file-box .btn-file {
  position: absolute;
  top: 0;
  right: 0;
  width: 115px;
  background: #222;
  color: #fff;
  font-size: 16px;
  text-align: center;
  height: 50px;
  line-height: 50px;
  letter-spacing: -0.04em;
}

.form-list .input-file-box input {
  font-size: 17px;
  color: #333;
  opacity: 0.5;
}

.form-box .txt {
  font-size: 17px;
  color: #000;
  opacity: 0.4;
  margin: 10px 0 0;
}

.btn-area {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.btn_disabled {
  background-color: #adadad;
  padding: 10px 30px;
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}
.btn_disabled span {
  color: #000000;
  font-size: 20px;
}
.btn_ok {
  background-color: #277ec9;
  padding: 10px 30px;
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}
.btn_ok span {
  color: #ffffff;
  font-size: 20px;
}
.btn_cancel {
  background-color: #277ec9;
  padding: 10px 30px;
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  margin-right: 30px;
}

.btn_cancel span {
  color: #ffffff;
  font-size: 20px;
}

.iSci_news {
  text-align: center;
  padding: 100px 0 200px;
  /* margin: 0 auto; */
}

.iSci_news .sub_news {
  font-size: 44px;
  font-weight: 700;
  color: #2d2d2d;
  letter-spacing: -3px;
  margin-bottom: 50px;
}

.iSci_news h2 .line {
  display: inline-block;
  position: relative;
}

.iSci_news h2 .line:after {
  content: "";
  display: block;
  height: 11px;
  margin-top: -15px;
  background-color: #0be0ef;
}

.iSci_news > p {
  font-size: 25px;
  font-weight: 500;
  color: #2d2d2d;
  line-height: 1.5;
  letter-spacing: 3px;
}

.iSci_news .news_list {
  margin: 50px;
}

.iSci_news .news_list:after {
  content: "";
  display: block;
  clear: both;
}

.iSci_news .news_list ul li {
  float: left;
  margin-left: 50px;
}

.iSci_news .news_list ul li:first-child {
  margin-left: 0px;
}

.iSci_news .news_list .news_item,
.iSci_news .news_list .news02,
.iSci_news .news_list .news03,
.iSci_news .news_list .news04,
.iSci_news .news_list .news05,
.iSci_news .news_list .news06 {
  /* width: 380px; */
  border: 1px solid #e0e0e0;
  padding-bottom: 25px;
}

.iSci_news .news_list .news_item:after,
.iSci_news .news_list .news02:after,
.iSci_news .news_list .news03:after,
.iSci_news .news_list .news04:after,
.iSci_news .news_list .news05:after,
.iSci_news .news_list .news06:after {
  content: "";
  display: block;
  clear: both;
}

.iSci_news .news_list .news_item p,
.iSci_news .news_list .news02 p,
.iSci_news .news_list .news03 p,
.iSci_news .news_list .news04 p,
.iSci_news .news_list .news05 p,
.iSci_news .news_list .news06 p {
  font-size: 17px;
  font-weight: 500;
  margin: 5px 5px 5px;

  /* height: 30px; */
  /* white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; */
}

.iSci_news .news_list .news_item span,
.iSci_news .news_list .news02 span,
.iSci_news .news_list .news03 span,
.iSci_news .news_list .news04 span,
.iSci_news .news_list .news05 span,
.iSci_news .news_list .news06 span {
  font-size: 17px;
  font-weight: 300;
}

.news_page {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.news_page .btn_page_prev {
  width: 40px;
  height: 38px;
  margin: 0 5px;
  border: 1px solid #707070;
}

.news_page .btn_page_next {
  width: 40px;
  height: 38px;
  margin: 0 5px;
  border: 1px solid #707070;
}

.news_page .btn_page_prev,
.news_page .btn_page_next {
  background: url(../lib/images/news_prev_arrow.png) no-repeat 50% 50%;
}

.news_page .btn_page_next {
  transform: rotate(180deg);
}

.news_page a {
  padding: 6px 15px;
  margin: 0 5px;
  border: 1px solid #707070;
}

.news_page .on {
  color: #ffffff;
  padding: 6px 15px;
  margin: 0 5px;
  background-color: #2d2d2d;
  border: 1px solid #2d2d2d;
}

.news_view {
  margin: 50px 0;
  padding: 0 63px;
}

.news_view:after {
  content: "";
  display: block;
  clear: both;
}

.news_view dt {
  position: relative;
  border-top: 2px solid #2d2d2d;
  border-bottom: 1px solid #2d2d2d;
  padding: 30px 17px;
  text-align: left;
}

.news_view .title {
  font-size: 30px;
  font-weight: 700;
}
.news_view .date {
  font-size: 20px;
  font-weight: 300;
  position: absolute;
  top: 37px;
  right: 17px;
}

.news_view .news_contents {
  margin-top: 20px;
}

.news_view .news_contents p {
  font-size: 20px;
  font-weight: 300;
  padding: 50px 17px;
  text-align: left;
  border-bottom: 1px solid #2d2d2d;
}

.btn_news_list {
  background-color: #277ec9;
  padding: 10px 30px;
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}

.btn_news_list span {
  color: #ffffff;
  font-size: 20px;
}

.news_prev {
  margin: 150px 63px 0;
  text-align: left;
}

.news_prev dl {
  position: relative;
  border-top: 1px solid #2d2d2d;
  border-bottom: 1px solid #2d2d2d;
}

.news_prev dt {
  position: absolute;
  font-size: 15px;
  font-weight: 400;
  top: 20px;
  left: 16px;
  width: 100px;
  background: url(../lib/images/news_view_prev.png) no-repeat 100% 63%;
  vertical-align: middle;
}

.news_prev dd {
  font-size: 20px;
  font-weight: 300;
  padding: 17px 0 17px 197px;
}

.news_prev .date {
  position: absolute;
  font-size: 15px;
  font-weight: 400;
  padding: 0;
  top: 20px;
  right: 16px;
}

.news_next {
  margin: 0 63px;
  text-align: left;
}

.news_next dl {
  position: relative;
  border-bottom: 1px solid #2d2d2d;
}

.news_next dt {
  position: absolute;
  font-size: 15px;
  font-weight: 400;
  top: 20px;
  left: 16px;
  width: 100px;
  background: url(../lib/images/news_view_next.png) no-repeat 100% 63%;
  vertical-align: middle;
}

.news_next dd {
  font-size: 20px;
  font-weight: 300;
  padding: 17px 0 17px 197px;
}

.news_next .date {
  position: absolute;
  font-size: 15px;
  font-weight: 400;
  padding: 0;
  top: 20px;
  right: 16px;
}

.iSci_welfare_system {
  text-align: center;
  padding: 100px 0 200px;
  margin: 0 auto;
}

.iSci_welfare_system .sub_welfare_system {
  font-size: 44px;
  font-weight: 700;
  color: #2d2d2d;
  letter-spacing: -3px;
  margin-bottom: 50px;
}

.iSci_welfare_system h2 .line {
  display: inline-block;
  position: relative;
}

.iSci_welfare_system h2 .line:after {
  content: "";
  display: block;
  height: 11px;
  margin-top: -15px;
  background-color: #0be0ef;
}

.iSci_welfare_system > p {
  font-size: 25px;
  font-weight: 500;
  color: #2d2d2d;
  line-height: 1.5;
  letter-spacing: 1px;
}

.welfare_system_contents1 {
  display: flex;
  justify-content: left;
  position: relative;
  justify-content: space-between;
  margin: 50px 0;
  border-top: 2px solid #e8e8e8;
  border-bottom: 2px solid #e8e8e8;
}

.welfare_system_contents1 .welsyscon1_left {
  width: 30%;
  padding: 50px;
  font-size: 30px;
  font-weight: 600;
  color: #2d2d2d;
}

.welfare_system_contents1 .welsyscon1_right {
  width: 80%;
  text-align: left;
  position: relative;
  font-size: 20px;
}

.welfare_system_contents1 .welsyscon1_right h5 {
  font-size: 25px;
  font-weight: 600;
  color: #277ec9;
  padding-bottom: 10px;
}

.welsyscon3_ourpeople h4 {
  font-size: 30px;
  font-weight: 600;
  color: #2d2d2d;
}

/* 인재채용 */
.iSci_recruitment {
  width: 70vw;
  text-align: center;
  padding: 100px 0 200px;
  margin: 0 auto;
}

.iSci_recruitment .sub_recruitment {
  font-size: 44px;
  font-weight: 700;
  color: #2d2d2d;
  letter-spacing: -3px;
  margin-bottom: 50px;
}

.iSci_recruitment h2 .line {
  display: inline-block;
  position: relative;
}

.iSci_recruitment > p {
  font-size: 25px;
  font-weight: 500;
  color: #2d2d2d;
  line-height: 1.5;
}

.recruitment_contents {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  margin: 50px 0;
  border-top: 2px solid #e8e8e8;
  border-bottom: 2px solid #e8e8e8;
}

.recruitment_contents .recruitment_right {
  width: 100%;
  text-align: left;
  position: relative;
  font-size: 20px;
}

.recruitment_contents .recruitment_right h5 {
  font-size: 25px;
  font-weight: 600;
  color: #277ec9;
  padding-bottom: 1rem;
}

.recruitment_contents .recruitment_right img {
  margin: 50px 0;
}

.recruitment_contents .recruitment_left:after {
  content: "";
  display: block;
  clear: both;
}

.recruitment_contents .recruitment_left p {
  font-size: 20px;
  color: #2d2d2d;
  padding: 50px 50px;
  line-height: 1.6;
}

.iSci_recruitment h4 {
  font-size: 30px;
  font-weight: 600;
  color: #2d2d2d;
  padding: 2rem 0;
}

/* 채용공고  */

.recruitment_area1,
.recruitment_area2 {
  display: flex;
  justify-content: left;
  position: relative;
  margin: 50px 63px 50px;
  padding-bottom: 50px;
  border-bottom: 2px solid #e8e8e8;
}

.recruitment_area1 .recruitment_left,
.recruitment_area2 .recruitment_left {
  width: 400px;
  text-align: left;
}

.recruitment_area1 .recruitment_left:after,
.recruitment_area2 .recruitment_left:after {
  content: "";
  display: block;
  clear: both;
}

.recruitment_area1 .recruitment_left h4,
.recruitment_area2 .recruitment_left h4 {
  display: block;
  font-size: 30px;
  font-weight: 600;
  color: #2d2d2d;
  position: absolute;
  top: 0;
  left: 16px;
}

.recruitment_area1 .recruitment_right,
.recruitment_area2 .recruitment_right {
  text-align: left;
  position: relative;
  font-size: 20px;
}

.recruitment_area1 .recruitment_right p,
.recruitment_area2 .recruitment_right p {
  font-size: 20px;
  color: #2d2d2d;
  padding: 0 0 50px;
  line-height: 1.6;
}

.recruitment_area1 .recruitment_right .apply,
.recruitment_area2 .recruitment_right .apply {
  border: 1px solid #707070;
  font-size: 20px;
  font-weight: 500;
  padding: 12px 36px;
}
.recruitment_area1 .recruitment_right .apply:hover {
  background-color: #277ec9;
  color: white;
}

.ourwork_contents .work02:hover {
  background: #ffffff;
  border: 1px solid #277ec9;
  border-radius: 30px;
  padding: 30px 45px;
  margin-top: 50px;
  box-shadow: 3px 3px 10px grey;
  box-sizing: border-box;
}

.iSci_aiSolution {
  text-align: center;
  padding: 100px 0 0;
  margin: 0 auto;
}

.iSci_aiSolution .sub_aiSolution {
  font-size: 44px;
  font-weight: 700;
  color: #2d2d2d;
  letter-spacing: -3px;
  /* margin-bottom: 50px; */
}

.iSci_aiSolution h2 .line {
  display: inline-block;
  position: relative;
}

.iSci_aiSolution h2 .line:after {
  content: "";
  display: block;
  height: 11px;
  margin-top: -15px;
  background-color: #0be0ef;
}

.iSci_aiSolution > p {
  font-size: 25px;
  font-weight: 500;
  color: #2d2d2d;
  line-height: 1.5;
  letter-spacing: 1px;
}

.iSci_aiSolution .sub_solution_conVisual {
  position: relative;
}

.iSci_aiSolution .sub_solution_conVisual img {
  margin-top: 130px;
  /* position: absolute; */
}

.iSci_aiSolution .sub_solution_conVisual .right {
  position: absolute;
  font-size: 1.3rem;
  right: 10%;
  top: 10%;
  text-align: left;
  max-width: 600px;
}

.aiSolution_contents {
  margin: 100px 100px 50px;
}

.aiSolution_contents h4 {
  display: block;
  font-size: 3rem;
  font-weight: 600;
  color: #2d2d2d;
}

.aiSolution_contents p {
  display: block;
  margin-top: 20px;
  font-size: 1.5rem;
  color: #2d2d2d;
}

.aiSolution_contents ul li {
  float: left;
  display: inline-block;
  background: url(../lib/images/bullet_nose.png) no-repeat 0 50%;
  padding: 20px;
}

.aiSolution_contents ul:after {
  content: "";
  display: block;
  clear: both;
}

.aiSolution_contents .infog_paradiam {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.aiSolution_contents .infog_comparison {
  margin-top: 50px;
}

.tit_bar {
  background-color: #3b9df3;
  /* height: 90px; */
  border-radius: 10px;
  position: relative;
  padding: 1rem;
  color: #ffffff;
  font-size: 2rem;
  font-weight: 600;
  margin-top: 1rem;
}

.aiSolution_contents .table_nose {
  margin-top: 50px;
}

.iSci_aiSolution_infog {
  background-color: #f1f8ff;
  text-align: center;
  padding: 100px 0;
  width: 1366px;
}

.iSci_aiSolution_infog .sub_aiSolution {
  font-size: 44px;
  font-weight: 700;
  color: #2d2d2d;
  letter-spacing: -3px;
}

.iSci_aiSolution_infog .sub_aiSolution strong {
  padding: 100px 0 65px;
}

.iSci_aiSolution_infog .inner {
  position: relative;
}

.iSci_aiSolution_infog .inner .page_list {
  display: block;
  position: absolute;
  left: 43px;
  top: 0px;
  z-index: 1;
}

.iSci_aiSolution_infog .inner .page_list li {
  float: left;
  width: 70px;
  height: 50px;
  line-height: 46px;
}

.iSci_aiSolution_infog .inner .page_list li a {
  font-size: 30px;
  font-weight: 400;
  padding: 0 5px;
}

.iSci_aiSolution_infog .inner .page_list li a.on {
  font-weight: 600;
  border: 2px solid #2d2d2d;
  padding: 0 5px;
}

.iSci_aiSolution_infog .inner .text_con {
  position: absolute;
  left: 60px;
  top: 70px;
  text-align: left;
  z-index: 1;
}

.iSci_aiSolution_infog .inner .text_con h3 {
  font-size: 30px;
  width: 200px;
  word-wrap: break-word;
}

.iSci_aiSolution_infog .inner:after {
  content: "";
  display: block;
  clear: both;
}

.iSci_aiSolution_infog .inner .img_con {
  margin: 65px 0 0 250px;
}
.how h4 {
  font-size: 44px;
}
.how {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
}

/* common */
.pdb50 {
  padding-bottom: 50px;
}
.pdt50 {
  padding-top: 50px;
}
.pdt100 {
  padding-top: 100px;
}
.pdb100 {
  padding-bottom: 100px;
}
.txt_blue {
  color: #277ec9;
}
.mart100 {
  margin-top: 100px;
}
.marb100 {
  margin-bottom: 100px;
}
.mart50 {
  margin-top: 50px;
}
.marb50 {
  margin-bottom: 50px;
}
