/* ----------------------------헤더 메뉴---------------------------------------- */
/* 대메뉴 호버 이펙트*/
.iSci_nav .nav_1depth button .hoverblue:hover {
  color: #277ec9;
}

/* 소메뉴 호버 이펙트 */
.iSci_nav .nav_1depth .nav_sub .sub-link:hover {
  color: #00b580;
}

.ourwork_contents a > div:hover {
  background: #ffffff;
  border: 1px solid #277ec9;
  border-radius: 30px;
  padding: 2.2rem 2.2rem;
  margin-top: 50px;
  box-shadow: 3px 3px 10px grey;
  box-sizing: border-box;
}

.news_contents a:hover span {
  color: #277ec9 !important;
}

.intro_company_nav ul li a:hover {
  color: #00b580;
  font-size: 20px;
  font-weight: 400;
}

strong.line::after {
  content: "";
  display: block;
  height: 11px;
  margin-top: -18px;
  background-color: #0be0ef;
}

.license_contents {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.license_contents span {
  font-size: 20px;
}

.license_contents h2 {
  /* align-items: center; */
  text-align: center;
  /* width: fit-content; */
  font-size: 44px;
  font-weight: 700;
  color: #2d2d2d;
  letter-spacing: -3px;
  padding-top: 100px;
  padding-bottom: 30px;
}
.license_contents section {
  display: flex;
  flex-direction: column;
  width: 100vw;
  justify-content: center;
  align-items: stretch;
  margin-bottom: 100px;
}
.license_contents img {
  width: 80vw;
  min-width: 500px;
  margin: auto;
}

.nav_lang_div {
  width: fit-content;
  position: relative;
}
.nav_lang_div ul {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.nav_lang_div .nav_lang {
  z-index: 1;
  display: flex;
  justify-content: center;
  padding-top: 1px;
}

.lang_kr_over {
  width: 28px;
  height: 28px;
  background: url("../lib/images/lang_kr_over.png") no-repeat 0 0;
}

.lang_en_over {
  width: 28px;
  height: 28px;
  background: url("../lib/images/lang_en_over.png") no-repeat 0 0;
}

.lang_vn_over {
  width: 28px;
  height: 28px;
  background: url("../lib/images/lang_vn_over.png") no-repeat 0 0;
}

#iSci_header .lang_kr:hover {
  width: 28px;
  height: 28px;
  background: url("../lib/images/lang_kr_over.png") no-repeat 0 0;
}
#iSci_header .lang_en:hover {
  width: 28px;
  height: 28px;
  background: url("../lib/images/lang_en_over.png") no-repeat 0 0;
}
#iSci_header .lang_vn:hover {
  width: 28px;
  height: 28px;
  background: url("../lib/images/lang_vn_over.png") no-repeat 0 0;
}

/* -----------------contact us--문의-------------------- */
.form-list .Group {
  border-bottom: 1px solid #e1e1e1;
  padding: 20px 0;
}
.form-list .Group dl {
  display: flex;
  width: 100%;
  table-layout: fixed;
}
.form-list .Group dl > * {
  display: flex;
  align-items: center;
}
.form-list .Group .label {
  width: 177px;
  padding-left: 30px;
  font-size: 20px;
  font-weight: 400;
  line-height: 56px;
  color: #2d2d2d;
}
.form-list .Group .control {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  text-align: left;
  font-size: 17px;
}
.form-list .Group dl dd > * {
  margin-right: 10px;
}

.select-box-ui {
  position: relative;
  width: 476px;
  max-width: 486px;
  border: 1px solid #e1e1e1;
  padding: 0 20px;
}

.select-box-ui .select {
  display: block;
  height: 100%;
  width: 100%;
  text-align: left;
  letter-spacing: -0.01em;
  font-size: 17px;
  box-sizing: border-box;
  border: none;
  background: transparent;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  font-size: 17px;
}
.select-box-ui option {
  position: relative;
  width: 100%;
  height: 56px;
  line-height: 54px;
  color: #2d2d2d;
  padding: 0 40px 0 20px;
  z-index: 5;
  cursor: pointer;
}
.select-box-ui .selected-option {
  position: relative;
  width: 100%;
  height: 56px;
  line-height: 54px;
  color: #2d2d2d;
  padding: 0 40px 0 20px;
  z-index: 5;
  cursor: pointer;
}

.textarea-box ::placeholder {
  position: absolute;
  top: 20px;
  left: 0;
  padding: 0 20px;
  width: 100%;
  /* font: inherit; */
  box-sizing: border-box;
  color: #333;
  opacity: 0.5;
  letter-spacing: 0.5px;
}

.textarea-box .control {
  padding: 20px;
  width: 800px;
  box-sizing: border-box;
  height: 182px;
  font-size: inherit;
  color: #222;
  border: 0;
  resize: none;
  font-family: "Noto Sans KR";
  font-weight: 300;
}

.email-select {
  padding: 0 0;
}

/* --------------------제품소개------------------------- */
.iSci_aiSolution_infog .inner .page_list button {
  font-size: 30px;
  font-weight: 400;
  padding: 0 5px;
}

.iSci_aiSolution_infog .inner .page_list li button.on {
  font-weight: 600;
  border: 3px solid #2d2d2d;
  padding: 0 5px;
}

.iSci_aiSolution_infog .inner .text_con {
  position: absolute;
  left: 40px;
  top: 70px;
  text-align: left;
  z-index: 1;
}

.table_license {
  width: 1183px;
  height: 100px;
  border-collapse: collapse;
  border: 1px solid #5b5b5b;
  margin-top: 20px;
}

.table_license thead {
  background-color: #e6e6e6;
}

.table_license thead,
.table_license tbody {
  height: 70px;
}

.table_license th,
.table_license td {
  border: 1px solid #5b5b5b;
  padding: 10px;
  width: 200px;
  text-align: center;
  font-size: 26px;
  font-weight: 400;
}

.table_license tr > :first-child {
  width: 25%;
}

.licence_img_div {
  text-align: center;
  margin-top: 50px;
}
.licence_img_div img {
  width: 581px;
  z-index: 1;
}
.licence_img_div :nth-child(2) {
  margin-left: 20px;
}

.nose_contents thead > tr > :nth-child(2) {
  background-color: #b0b0b0;
}

.nose_contents tr > :nth-child(2) {
  background-color: #e6e6e6;
}

.nose_contents thead > tr > :nth-child(3) {
  background-color: #4a9ce3;
  color: white;
}

.nose_contents tr > :nth-child(3) {
  background-color: #d6e6f3;
  font-weight: bold;
  color: #1270c0;
}

/* =========================================Licence.js */

.licence_item {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 410px;
  margin: 0 120px;
}
.licence_item img {
  height: 350px;
  margin: 5px;
}
.licence_item > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

/* ----제품소개 carousel--- */
.carousel__slide--focused,
.carousel__slide--focused:focus,
.carousel__inner-slide,
.carousel__inner-slide:focus {
  outline: none;
}
.carousel__slide-focus-ring,
.carousel__slide-focus-ring:focus,
.carousel___1airF,
.carousel___1airF:focus {
  outline: none;
}

/* ----top button---- */
.top-button {
  background: url("../lib/images/btn_TOP.png") no-repeat 50% 50%;
}

/* -----문의----- */

.searchBox::placeholder {
  font-size: 13px;
  width: 1000px;
  padding: "0 20px";
}

/* -----------연혁------------- */
.intro_history .infograph img {
  width: 60vw;
}
.intro_history .infograph .left ul li button {
  color: #277ec9;
  font-size: 30px;
  font-weight: 500;
}

.intro_history .infograph .left ul .on {
  width: 150px;
  padding: 30px 0;
  background: url("../lib/images/dot_on_history.png") no-repeat 100% 50%;
}

.intro_history .infograph .left ul .on button {
  color: #277ec9;
  font-size: 40px;
  font-weight: 700;
}

.infograph .left .box {
  display: flex;
  justify-content: center;
  width: 300px;
  height: 600px;
  overflow: auto;
  /* border: 10px solid cyan; */
  -ms-overflow-style: none;
  z-index: 1;
}

.infograph .left .box::-webkit-scrollbar {
  display: none;
}

.infograph .left ul {
  margin: 10px;
  z-index: 0;
}

.infograph .right .box {
  display: flex;
  justify-content: left;
  width: auto;
  height: 1000px;
  overflow: auto;
  /* border: 10px solid cyan; */
  -ms-overflow-style: none;
  z-index: 1;
}

.infograph .right .box::-webkit-scrollbar {
  display: none;
}

/* ---------landing page advertise zone--------- */
.video_area {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 비율 */
}
.video {
  position: absolute;
  width: 100%;
  height: 100%;
}
.advertise_background {
  background: url("../lib/images/img_filming.jpg") no-repeat 50% 50%;
  background-size: auto;
  background-color: #000000;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.advertise {
  justify-content: space-around;
  align-items: center;

  width: 80vw !important;
  margin: 0 auto;
  padding: 5rem 0;
}

.advertise_header {
  font-size: 2rem;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: -3px;
}

.advertise_header .highlight {
  font-size: 3.5rem;
  font-weight: 700;
  color: #faf375;
  letter-spacing: -3px;
}
.advertise h2 .line {
  display: inline-block;
  position: relative;
}

.advertise h2 .line:after {
  content: "";
  display: block;
  height: 11px;
  margin-top: -22px;
  background-color: #750bef;
}

.advertise p {
  margin-top: 20px;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
}

/* -------신규모집------- */
.recruit_process {
  display: flex;
  justify-content: space-between;
  margin: 30px;
}

.recruit_process img {
  width: 60%;
  height: auto;
}

.recruit_process li {
  display: flex;
  flex-direction: column;
  width: 20%;
  /* align-items: center; */
}

.recruit_process h2 {
  color: #00b580;
  font-weight: 700;
  font-size: 30px;
}

.recruit_process p {
  font-weight: 700;
  letter-spacing: -2px;
}

.recruit_process :nth-child(3) :last-child {
  font-size: 15px;
  font-weight: 500;
  margin-top: 5px;
}

/* ------------news 뉴스----------- */

.news_item_img_container {
  height: 350px;
}

.news_item_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.news_link {
  color: #277ec9;
}

.news_link:hover {
  background-color: #4a9ce3;
  color: white;
}

.news-form {
  display: flex;
  flex-direction: column;
}
.news-form label {
  height: 100%;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
}

.news-form input {
  width: 80%;
  height: 3rem;
  margin-left: 10px;
}

.news-form textarea {
  resize: none;
  margin-left: 10px;
  width: 80%;
  height: 200px;
}

/* ------------landing page 랜딩페이지---------- */

.main_background {
  background-color: #277ec9;
}

.ourwork_background {
  background-color: #f1f8ff;
}
.contactus_background {
  background-color: #000000;
}

.footer_background {
  background-color: #277ec9;
}

.licences {
  /* height: 100px; */
  display: flex;
  flex-direction: row-reverse;
  width: 1000px;
  /* align-items: flex-end; */
}

.our_people {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 20px;
}

.our_people hr {
  color: #e8e8e8;
  border: 0.5px solid #e8e8e8;
}

.welsyscon3_ourpeople > :nth-child(2) {
  border-bottom: 2px solid #e8e8e8;
}

.our_people > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  height: 300px;
  justify-content: space-around;
}

.our_people > div > img {
  width: 120px;
}
.our_people > div > p {
  font-size: 20px;
}

.error-404 {
  display: flex;
  justify-content: center;
}
