.pagination {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
ul {
  list-style: none;
  padding: 0;
}
ul.pagination li {
  display: inline-block;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  width: 40px;
  height: 40px;
  margin: 0 5px;
  border: 1px solid #707070;
  cursor: pointer;
}
/* << */
ul.pagination li:first-child {
  background: url(../lib/images/news_first_arrow.png) no-repeat 50% 50%;
}
ul.pagination li:first-child:hover p {
  background-color: #2d2d2d;
  background: url(../lib/images/news_first_ov_arrow.png) no-repeat 50% 50%;
}
/* >> */
ul.pagination li:last-child {
  background: url(../lib/images/news_first_arrow.png) no-repeat 50% 50%;
  transform: rotate(180deg);
}
ul.pagination li:last-child:hover p {
  background-color: #2d2d2d;
  background: url(../lib/images/news_first_ov_arrow.png) no-repeat 50% 50%;
}
/* < */
ul.pagination li:nth-child(2) p {
  background: url(../lib/images/news_prev_arrow.png) no-repeat 50% 50%;
}
ul.pagination li:nth-child(2):hover p {
  background: url(../lib/images/news_prev_ov_arrow.png) no-repeat 50% 50%;
}
/* > */
ul.pagination li:nth-last-child(2) {
  background: url(../lib/images/news_prev_arrow.png) no-repeat 50% 50%;
  transform: rotate(180deg);
}
ul.pagination li:nth-last-child(2):hover p {
  background: url(../lib/images/news_prev_ov_arrow.png) no-repeat 50% 50%;
}

ul.pagination li a {
  text-decoration: none;
  color: #000000;
}

ul.pagination li:hover {
  background-color: #2d2d2d;
  /* color: #ffffff; */
}
ul.pagination li:hover > * {
  background-color: #2d2d2d;
  color: #ffffff;
}
ul.pagination li a:hover,
ul.pagination li a.active {
  color: #ffffff;
}

ul.pagination li.active a {
  color: white;
}
ul.pagination li.active {
  background-color: #2d2d2d;
}
.page-selection {
  width: 48px;
  height: 30px;
  color: #337ab7;
}
